import React from "react";
import Vision from "./Vision";
import Values from "./Values";
import Contact from "./Contact";
import Mission from "./Mission";
import Operate from "./Operate";
import WhatWeDo from "./WhatWeDo";

function Home() {
  return (
    <div className="home">
      <Vision />
      <Values />
      <Mission />
      <Operate home />
      <WhatWeDo />
      <Contact />
    </div>
  );
}

export default Home;
