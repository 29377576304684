import React from "react";
import Hero from "../components/Hero";
import Title from "../components/Title";

function Values() {
  return (
    <div className="values">
      <Hero image="/images/Our Values.jpg" />

      <section className="main container">
        <Title>Our values</Title>

        <section>
          <div>
            <h3>ethics</h3>
            <p>
              We maintain highest standards in transparency and ethical behavior
            </p>
          </div>

          <div>
            <h3>accountability</h3>
            <p>
              We believe that stakeholders, including ourselves, must take
              responsibility for their impacts
            </p>
          </div>

          <div>
            <h3>sustainability</h3>
            <p>We make sustainability and competitiveness go hand in hand</p>
          </div>

          <div>
            <h3>innovation</h3>
            <p>
              We utilise better data and innovative solutions to enhance
              analysis and outputs
            </p>
          </div>

          <div>
            <h3>stakeholder cooperation</h3>
            <p>We believe in combined insights and impacts</p>
          </div>

          <div>
            <h3>context sensitivity</h3>
            <p>
              We believe that local insights and partnerships are key to
              interventions in high-risk areas
            </p>
          </div>
        </section>
      </section>
    </div>
  );
}

export default Values;
