import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Vision from "./screens/Vision";
import Values from "./screens/Values";
import Contact from "./screens/Contact";
import Mission from "./screens/Mission";
import Operate from "./screens/Operate";
import WhatWeDo from "./screens/WhatWeDo";
import Home from "./screens/Home";

function Root() {
  return (
    <Router>
      <Nav />

      <Switch>
        <Route exact path="/">
          <Home />
        </Route>

        <Route exact path="/vision">
          <Vision />
        </Route>

        <Route exact path="/values">
          <Values />
        </Route>

        <Route exact path="/mission">
          <Mission />
        </Route>

        <Route exact path="/what-we-do">
          <WhatWeDo />
        </Route>

        <Route exact path="/where-we-operate">
          <Operate />
        </Route>

        <Route exact path="/contact-us">
          <Contact />
        </Route>
      </Switch>

      <Footer />
    </Router>
  );
}

export default Root;
