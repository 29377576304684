import React from "react";
import Hero from "../components/Hero";
import BulletList from "../components/BulletList";
import Title from "../components/Title";

function WhatWeDo() {
  return (
    <div className="what-we-do">
      <Hero image="/images/What We Do.jpg" />

      <div className="container">
        <Title>What we do</Title>
      </div>

      <section className="container">
        <BulletList
          list={[
            {
              title: "Investigation, Research and Analysis",
              items: [
                "Stakeholder and supply chain mapping.",
                "Impact assessments and supply chain risk assessments.",
                "Field research, investigation & risk intelligence in high-risk & hard-to-access areas.",
                "Analysis of complex networks of companies and actors involved in global trade of natural resources (including Illicit trafficking networks, IFFs, & threat financing).",
                "Political and social economy analysis.",
              ],
            },
            {
              title: "Consulting and strategic advisory",
              items: [
                "Clarify your vision and strategic objectives on sustainability and responsible sourcing and trade.",
                "Theory of change design; monitoring and evaluation (M&E) of impact-related performance and projects.",
                "Risk assessment & due diligence processes (KYC, CoC).",
                "Set-up and implement due diligence processes in conformance/compliance with OECD, US, EU guidance, regulatory frameworks and other industry initiatives.",
                "Inputs into policy reforms, negotiations, implementation including natural resource governance, trade and business environment.",
                "Development of specific recommendations, strategies and policies for improved outcomes for government and international organisations.",
                "Strategize for clients’ beyond-compliance impacts in responsible business conduct.",
                "Support company reporting with performance focus on Environmental, Social, and Governance (ESG) rankings.",
              ],
            },
            {
              title:
                "Develop and implement sustainability programming - gaining and maintaining the social license to operate",
              items: [
                "Provide an exponentially greater understanding of local sensitivities and concerns, often critical in conflict-affected and high risk areas, as well as management of expectations, and tangible positive impacts for local communities.",
                "Guide clients in avoiding pitfalls and mis-steps which can in a matter of minutes irrevocably lose the trust of local communities, trust which can have taken years to nurture and develop.",
                "Help companies navigate sustainability issues - HIVE’s team experience, with its network of local experts, provides clients with a turnkey capacity to manage expectations, deliver on those expectations, demonstrate sensitivity to stakeholder concerns, and to win and maintain the trust of local communities.",
                "Set up and implement beyond-compliance impact projects projects and partnerships (as part of CSR/Sustainability policy or Foundation or external partnerships).",
              ],
            },
            {
              title: "Data and technology solutions",
              items: [
                () => (
                  <>
                    Standardized templates / tools for professionalized data
                    collection and assessment related to sourcing.", "Data
                    mapping and visualization.", "Standardization of Monitoring
                    &amp; Evaluation (M&amp;E) processes.", "Non-profit
                    stewardship of the Consolidated Autonomous Due Diligence{" "}
                    <a href="https://medium.com/betterchain/consolidated-autonomous-due-diligence-cadd-2a8c97bdf20">
                      (CADD Framework)
                    </a>
                    .
                  </>
                ),
              ],
            },
            {
              title: "Capacity building and technical assistance",
              items: [
                "For HIVE, capacity building means training, mentoring and creating the environment for implementation.",
                "Mentoring and training of in-house teams, suppliers, stakeholders (private and public sectors).",
                "Technical assistance to support trade and development in cooperation with local partners, bilateral funding agencies (European Commission, DFID, GIZ, other) and international organisations (OECD, UN, International Trade Centre (ITC), WB).",
              ],
            },
          ]}
        />
      </section>
    </div>
  );
}

export default WhatWeDo;
