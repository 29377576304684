import React, { useState, useRef, useEffect } from "react";
import { useDrag } from "react-use-gesture";

function Carousel({ children, images, className = "" }) {
  const container = useRef();
  const [active, setActive] = useState(0);
  const [maxHeight, setMaxHeight] = useState("unset");

  const bind = useDrag(
    ({ movement: m, active: isActive }) => {
      const offset = m[0];
      const threshold = container.current.offsetWidth / 4;

      if (!isActive) {
        if (Math.abs(offset) < threshold) {
          container.current.scrollTo({
            left: container.current.offsetWidth * active,
            behavior: "smooth",
          });
        } else {
          offset < 0 ? onNext() : onPrev();
        }
      } else
        container.current.scrollTo({
          left: container.current.offsetWidth * active - offset,
        });
    },
    { domTarget: container, axis: "x" }
  );

  useEffect(bind, [bind]);

  const onPrev = () => {
    if (active > 0) {
      container.current.scrollTo({
        top: 0,
        left: container.current.offsetWidth * (active - 1),
        behavior: "smooth",
      });
      setActive((a) => a - 1);
      setMaxHeight(container.current.children[active - 1].offsetHeight);
    }
  };

  const onNext = () => {
    if (active < children.length - 1) {
      container.current.scrollTo({
        top: 0,
        left: container.current.offsetWidth * (active + 1),
        behavior: "smooth",
      });
      setActive((a) => a + 1);
      setMaxHeight(container.current.children[active + 1].offsetHeight);
    }
  };

  const onClickDot = (i) => {
    container.current.scrollTo({
      top: 0,
      left: container.current.offsetWidth * i,
      behavior: "smooth",
    });
    setActive(i);
    setMaxHeight(container.current.children[i].offsetHeight);
  };

  useEffect(() => {
    setMaxHeight(container.current.children[0].offsetHeight);
  }, [container.current]);

  // useEffect(() => {
  //   const t = setInterval(() => {
  //     console.log(active);
  //     if (active === children.length - 1) {
  //       container.current.scrollTo({
  //         top: 0,
  //         left: 0,
  //       });
  //       setActive(0);
  //     } else onNext();
  //   }, 1000);

  //   return () => clearInterval(t);
  // }, []);

  return (
    <div className={`carousel-slider ${className}`}>
      <div className="dots">
        {images.map((image, i) => (
          <button
            key={i}
            onClick={() => onClickDot(i)}
            className={i === active ? "active" : ""}
          >
            <img src={image} />
          </button>
        ))}
      </div>

      <div ref={container} style={{ maxHeight }} className="slider">
        {children.map((slide, i) => (
          <div className="slide" key={i}>
            {slide}
          </div>
        ))}
      </div>

      <div
        onClick={onPrev}
        className={`arrow prev${active === 0 ? " disabled" : ""}`}
      />
      <div
        onClick={onNext}
        className={`arrow next${
          active === children.length - 1 ? " disabled" : ""
        }`}
      />
    </div>
  );
}

export default Carousel;
