import React from "react";

function BulletList({ list, simple = false }) {
  return (
    <section className={`bullet-list${simple ? " simple" : ""}`}>
      {list.map(({ title, items }) => (
        <div key={title}>
          <h2>{title}</h2>

          <ul>
            {items.map((item, i) => (
              <li key={i}>{typeof item === "function" ? item() : item}</li>
            ))}
          </ul>
        </div>
      ))}
    </section>
  );
}

export default BulletList;
