import React from "react";
import Title from "../components/Title";
import Hexagon from "../components/Hexagon";
import BulletList from "../components/BulletList";

function Mission() {
  return (
    <div className="mission">
      <div className="container">
        <Title>Mission - areas of focus</Title>

        <section className="display">
          <img src="/images/4.jpg" />

          <div className="copy">
            <Hexagon size={380} />
            <span>
              We are a non-profit based in Brussels, Belgium. Our mission
              centers on issues and challenges related to responsible sourcing,
              trade, governance and security, especially in high-risk areas
            </span>
          </div>
        </section>

        <BulletList
          list={[
            {
              title: "Sustainable business",
              items: [
                "Ensure that production and investment benefits local and national economies, all the while respecting social and environmental conditions and allowing the private sector to make a reasonable and viable return.",
                "Promote continuous improvement in responsible business practices, through compliance with legal frameworks and alignment with industry schemes.",
                "Guide businesses in both design and implementation of their socially responsible sustainability programs in high-risk areas.",
                "Help the private sector move beyond compliance so that business impacts are sustainable and contribute to development.",
                "Support Government and stakeholders to assess and engage for more sustainable business practices.",
              ],
            },
            {
              title: "Insightful granular analysis",
              items: [
                "Provide in-depth and context-sensitive insights related to sourcing from and trade with high risk areas.",
                "Provide collation and analysis of reliable data.",
                "Investigate and help stakeholders prevent illicit / illegal practices in supply chains.",
              ],
            },
            {
              title: "Policies for good governance",
              items: [
                "Support Governments in enhancing the business environment for responsible trade and sourcing through better regulations.",
                "Provide policy recommendations to improve benefits from responsible trade and sourcing for all stakeholders.",
                "Ensure policy and legislative proposals depart from sound data and analysis.",
                "Facilitate stakeholder engagement and dialogue in reform processes.",
              ],
            },
            {
              title: "Increased market access for local actors",
              items: [
                "Create tangible added value along the supply chain by devising practical tools rooted in local and business realities.",
                "Work alongside stakeholders in resource-rich countries to improve the terms of their engagement with the global private sector.",
              ],
            },
            {
              title: "Bridge upstream and downstream",
              items: [
                "Guide stakeholders in engagement with local and international players.",
                "Stimulate context sensitivity through listening and learning from all stakeholders.",
                "Provide a platform to make local communities' voices heard.",
                "Establish new communication and engagement channels between consumers and communities at the upstream of global supply chains.",
              ],
            },
            {
              title: "Partnerships for development and impact",
              items: [
                "Maintain internal and external relationships in support of due diligence systems for responsible sourcing and trade.",
                "Initiate and nurture partnerships to promote social and economic development in local communities.",
                "Support inclusiveness and stakeholder relations.",
              ],
            },
          ]}
        />
      </div>
    </div>
  );
}

export default Mission;
