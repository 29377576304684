import React from "react";
import Hero from "../components/Hero";
import Title from "../components/Title";
import BulletList from "../components/BulletList";
import { VectorMap } from "react-jvectormap";
import { getCode } from "country-list";
import Carousel from "../components/Carousel";

const countries = [
  "Angola",
  "Benin",
  "Burkina Faso",
  "Burundi",
  "Cameroon",
  "Central African Republic",
  "Chad",
  "Congo, Democratic Republic of the",
  "Ethiopia",
  "Ghana",
  "Guinea",
  "Côte d'Ivoire",
  "Kenya",
  "Liberia",
  "Madagascar",
  "Mali",
  "Mauritania",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Niger",
  "Nigeria",
  "Congo",
  "Rwanda",
  "Senegal",
  "Sierra Leone",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Sudan",
  "Tanzania",
  "Togo",
  "Uganda",
  "Zambia",
  "Zimbabwe",
  "Kazakhstan",
  "Kyrgyzstan",
  "Tajikistan",
  "Azerbaijan",
  "Cambodia",
  "Indonesia",
  "LaoPDR",
  "Malaysia",
  "Myanmar",
  "Philippines",
  "Singapore",
  "Thailand",
  "Vietnam",
  "China",
  "India",
  "Canada",
  "United States of America",
  "Bolivia",
  "Brazil",
  "Colombia",
  "Mexico",
  "Peru",
  "Kosovo",
  "Serbia",
  "Albania",
  "Bosnia and Herzegovina",
  "Belarus",
  "Moldova, Republic of",
  "Russian Federation",
];

const members = [
  <BulletList
    key={1}
    simple={true}
    list={[
      {
        title: "Rupert Cook",
        items: [
          "Rupert specialises in research and analysis, stakeholder and supply chain mapping, in conflict-affected and high-risk areas. For more than 20 years, he has explored the challenges of responsible mineral sourcing, as well as the nexus between poverty, conflict and natural resources, in 35+ countries, mainly in Africa and Asia.",
          "He served as natural resources and finance expert with the UN Security Council Group of Experts for the DRC, in 2015-16, focusing on illegal trafficking, especially 3TG and ivory, and underlying governance issues.",
          "For UNODC, Rupert authored the Threat Assessment on Organized Crime and Trafficking of Natural Resources in the Great Lakes Region (2018). In 2019-20, he wrote 3 major studies for USAID on the relationship between transnational organised crime and violent extremist groups in Burkina Faso, Mali and Niger. He has written multiple studies investigating illegal gold, 3T, diamond, gemstone and other mineral supply chains, looking particularly at the role played by gold and other high-value minerals in money-laundering, illicit financial flows, and threat financing.",
          "He has carried out due diligence and risk assessments on 20+ 3T and other mineral supply chains, including cobalt, in the Great Lakes Region. For the ICGLR, he was senior consultant in the development of its Gold Due Diligence Strategy, and co-authored the ICGLR Third Party Audit Methodology.",
          "Rupert conducted detailed case studies on LSM CSR/Sustainability for a two-year World Bank study looking at the contribution of extractive industries to development in Madagascar. From the Sahel and Great Lakes Region, he has written extensively on conflicts between LSM and ASM, and ways to avoid/mitigate/resolve such conflicts.",
          "In 2013-15, he served as Industry Fellow at the University of Queensland’s Center for Social Responsibility in Mining.",
          "Clients include the World Bank, the UN, USAID, the US Department of Justice, GIZ, BGR, as well as a range of governments, corporate clients, and multiple NGOs.",
          "Rupert was awarded a First Class Hons degree from the University of Cambridge, a DAAD scholarship at Heidelberg University, and a postgraduate Benjamin Franklin Fellowship from the University of Pennsylvania.",
          "He is based in Madagascar and Ireland.",
        ],
      },
    ]}
  />,
  <BulletList
    key={2}
    simple={true}
    list={[
      {
        title: "Hélène Helbig de Balzac",
        items: [
          "Hélène is a supply chain due diligence and mining trafficking networks specialist in conflict affected and high-risk areas (CAHRAs).",
          "She has more than 10 years of experience in international development with a focus on good governance, conflict prevention, and natural resources management in sub-Saharan Africa (DRC, Burundi, Rwanda, Ivory Coast, Burkina Faso, Mali).",
          "Hélène has collaborated with various firms and NGOs to develop innovative strategies for mineral supply chain due diligence; and has carried out several investigations on the illicit trade of gold in West and Central Africa.",
          "She has established a wide network of contacts amongst private sector businesses, industry associations, international organisations and civil society actors committed towards responsible sourcing practices.",
          "Hélène holds a master’s degree in sociology from the Université Libre de Bruxelles (ULB) and a Msc in Comparative Politics from the London School of Economics (LSE).",
        ],
      },
    ]}
  />,
  <BulletList
    key={3}
    simple={true}
    list={[
      {
        title: "Yannick Weyns",
        items: [
          "Yannick has a decade’s worth of experience working with national Governments and the international community in Central Africa to understand and have a transformational  impact on the links between mining, conflict and development in the region.",
          "Since 2016, Yannick has been the advisor on natural resources at the United Nations Stabilization Mission (MONUSCO) in the DR Congo.",
          "Prior to his position at MONUSCO, he worked for the International Peace Information Service, mapping conflict dynamics and mining sites in the DR Congo, Sudan, South Sudan and the Central African Republic for clients such as the World Bank and USAID.",
          "Yannick also has a keen understanding of human rights issues, having worked as a Human Rights Officer for the United Nations in Kinshasa.",
          "Yannick holds MAs in Law, Social and Cultural Anthropology (University of Leuven) and International Relations and Diplomacy (University of Antwerp).",
          "Pretends knowing something about cosmology and molecular biology too.",
        ],
      },
    ]}
  />,
  <BulletList
    key={4}
    simple={true}
    list={[
      {
        title: "Famke Schaap",
        items: [
          "Famke specializes in responsible business and global trade. She has spent the past 15 years working with corporate and government clients, in the EU and in emerging markets. Her core expertise is in translating policy requirements into business priorities and processes. Her profile blends expertise in both trade and sustainability.",
          "Famke is a former Director with Deloitte Belgium, where she served corporate and European Commission clients on global trade and risk sustainability. As an independent expert, she conducts assignments as part of trade related technical assistance programs (including DFID, EC, UNDP, WTO, UN/ITC, Netherlands Government and various other consulting firms). Prior to working with HIVE, Famke also served as EU/WTO Trade Diplomat for Netherlands Ministry of Foreign Affairs, as well as UN/WTO agency ITC in the role of Business and Trade Policy Adviser.",
          "Famke holds an MA in International Relations (Sussex University), and a certificate in Business Sustainability Management (Cambridge Institute for Sustainability Leadership).",
          "Being Brussels-based, Famke loves jazz concerts, playing tennis and occasionally leaving for mountain hiking and cross-country skiing!",
        ],
      },
    ]}
  />,
  <BulletList
    key={5}
    simple={true}
    list={[
      {
        title: "Benjamin Clair",
        items: [
          "Benjamin is an expert in mining and the global mineral trade, with experience in banking and commodity trading and specific expertise in the fields of Artisanal & Small-scale Mining (ASM) and supply chain due diligence. Benjamin is a former consultant on minerals sourcing with RCS global and brings over 15 years of experience working in high-risk areas.",
          "In addition, Benjamin is the founder of supply chain innovation firm BetterChain, where he develops solutions to improve access to information in conflict-affected and high-risk areas.",
          "Since 2012 he has worked in resource-rich countries, particularly in Central Africa, implementing large projects and building a swarm of local information relays.",
          "Benjamin holds an MA in International Trade & Finance, Paris IX – Dauphine University.",
          "Decent to hang out with when in a good mood.",
        ],
      },
    ]}
  />,
];

const mapData = countries.reduce(
  (map, c) => {
    map[getCode(c)] = 1;
    return map;
  },
  { XK: 1 }
);

function Operate({ home = false }) {
  return (
    <div className="operate">
      <div className="container head">
        <h2>
          Based in Brussels, Belgium, HIVE carries on-the-ground experience and
          leverages a large network across the globe.
        </h2>
      </div>

      <div className="map">
        <VectorMap
          className="map"
          map={"world_mill"}
          backgroundColor="transparent"
          panOnDrag={false}
          zoomOnScroll={false}
          containerStyle={{
            width: "100%",
            height: "520px",
          }}
          regionStyle={{
            initial: {
              fill: "#ccc",
            },
            hover: {
              "fill-opacity": 0.5,
              cursor: "pointer",
            },
          }}
          series={{
            regions: [
              {
                values: mapData,
                scale: ["#faa147"],
              },
            ],
          }}
        />
      </div>

      <section className="info container">
        <p>
          HIVE is the sum of its international and local experts. HIVE’s network
          of international and local experts spans the globe. Our contacts and
          partners include stakeholders from public sector institutions,
          international organizations, private sector (associations), civil
          society organizations, academia and research.
        </p>

        <span>
          <img src="/images/logo/logo-symbol.png" />
        </span>

        <p>
          The HIVE team draws upon an exceptional partnership with local
          experts, developed and nurtured over years of experience in the field
          - providing and assuring information gathering and data collection,
          continuous monitoring of supply chains and real-time identification of
          risk and impact.
        </p>

        <span>
          <img src="/images/logo/logo-symbol.png" />
        </span>

        <p>
          The depth and breadth of this network is one of the key factors which
          gives HIVE a critical edge. As well as ensuring an unparalleled
          attention to granular detail in terms of research and analysis, this
          also allows the HIVE team to work remotely when needed, whether in
          exceptionally high-risk areas or during periods of travel lockdown,
          e.g. COVID-19.
        </p>
      </section>

      <section className="team">
        <Hero image="/images/Our Team.jpg" />

        <div className="container copy">
          <Title>Our team</Title>

          <p>
            The HIVE team combines years of experience working in the field of
            responsible trade and sourcing, especially in emerging countries and
            conflict affected and high-risk areas (CAHRAs). Through our experts,
            Hive offers a proven track-record with concrete references in the
            field of responsible sourcing and trade.
            <br />
            <br />
            With its core seat in Brussels (Belgium), HIVE relies on an
            extensive network of experts on every continent, including in
            high-risk areas. Besides our central office in Brussels, HIVE
            individual team members are based in Madagascar, Democratic Republic
            of the Congo, and Spain.
            <br />
            We leverage a wide network of contacts amongst private sector
            businesses, industry associations, international organizations,
            civil society and government actors.
          </p>
        </div>
      </section>

      <Carousel
        className="members"
        images={[
          "/images/team/rupert.jpg",
          "/images/team/helene.jpg",
          "/images/team/yannick.jpg",
          "/images/team/femke.jpg",
          "/images/team/benjamin.jpg",
        ]}
      >
        {members}
      </Carousel>
    </div>
  );
}

export default Operate;
