import React, { useState, useEffect, useRef } from "react";
import Hero from "../components/Hero";
import { Map as RMap, GoogleApiWrapper, Marker } from "google-maps-react";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";

const SITE_KEY = "6Ld66MMZAAAAAJkORev97mMvKtIqTllWuxXAm1nD";

const url =
  process.env.NODE_ENV === "development"
    ? "https://4jx0ulv7b3.execute-api.eu-central-1.amazonaws.com/dev/email/send"
    : "https://2ha58zqhhg.execute-api.eu-central-1.amazonaws.com/prod/email/send";

const Map = GoogleApiWrapper({
  apiKey: "AIzaSyBMxxIFFomHF2E-14f-mULWcsXqQQMmSPs",
})(({ google }) => {
  return (
    <RMap
      streetViewControl={false}
      initialCenter={{
        lat: 50.8293601,
        lng: 4.3468873,
      }}
      containerStyle={{
        position: "relative",
        height: "300px",
      }}
      google={google}
    >
      <Marker
        position={{
          lat: 50.8293601,
          lng: 4.3468873,
        }}
      />
    </RMap>
  );
});

function Contact() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [token, setToken] = useState(false);
  const reCaptcha = useRef();

  const verifyCallback = (t) => {
    setToken(t);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (firstName && lastName && email && subject && message)
      try {
        await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
          body: JSON.stringify({
            email,
            firstName,
            lastName,
            subject,
            message,
            token,
          }),
        });

        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          setError(false);
        }, 5000);
      } catch (e) {
        setError(true);
        setTimeout(() => {
          setSuccess(false);
          setError(false);
        }, 5000);
      }

    setLoading(false);
  };

  useEffect(() => {
    loadReCaptcha(SITE_KEY);
  }, []);

  return (
    <div className="contact">
      <Hero image="/images/2.jpg" />

      <section className="container info">
        <div>
          <h2>Address</h2>
          Hive Asbl
          <br />
          Avenue Louise 367
          <br />
          1050 Ixelles (Brussels)
          <br />
          Belgium
        </div>

        <Map />
      </section>

      <form onSubmit={onSubmit} className="container">
        <h2>Contact us</h2>

        <div className="name">
          <label>First name</label>
          <input
            value={firstName}
            onChange={({ target: { value } }) => setFirstName(value)}
            required
          />
        </div>

        <div className="name">
          <label>Last name</label>
          <input
            value={lastName}
            onChange={({ target: { value } }) => setLastName(value)}
            required
          />
        </div>

        <div>
          <label>Email</label>
          <input
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
            type="email"
            required
          />
        </div>

        <div>
          <label>Subject</label>
          <input
            value={subject}
            onChange={({ target: { value } }) => setSubject(value)}
            required
          />
        </div>

        <div>
          <label>Message</label>
          <textarea
            rows={8}
            value={message}
            onChange={({ target: { value } }) => setMessage(value)}
            required
          />
        </div>

        {error && (
          <div className="error">Something went wrong, please try again</div>
        )}

        {success && (
          <div className="success">Your message has been sent successfully</div>
        )}

        <ReCaptcha
          ref={reCaptcha}
          sitekey={SITE_KEY}
          verifyCallback={verifyCallback}
        />

        <button type="submit">{loading ? "Submitting..." : "Submit"}</button>
      </form>
    </div>
  );
}

export default Contact;
