import React from "react";

function Hexagon({ size = 100 }) {
  return (
    <div
      className="hexagon"
      style={{ minWidth: size * 1.1, minHeight: size }}
    />
  );
}

export default Hexagon;
