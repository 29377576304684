import React from "react";
import Hero from "../components/Hero";
import Title from "../components/Title";

function Vision() {
  return (
    <div className="vision">
      <Hero image="/images/Our Vision.jpg">
        HIVE - Making the global and the local meet and mutually benefit in
        high-risk areas
      </Hero>

      <section className="main container">
        <Title>Our vision</Title>

        <div>
          <div>
            From policy to practice in responsible sourcing, HIVE’s vision is to
            realize a future where there are tangible benefits for communities,
            companies and consumers
          </div>
          <div>
            Focusing especially on high-risk areas, HIVE’s vision is to confront
            and resolve challenges related to responsible sourcing and trade
          </div>
          <div>
            Combining ethical perspectives with years of expert experience,
            HIVE’s vision is to make the global and the local meet and mutually
            benefit
          </div>
        </div>
      </section>
    </div>
  );
}

export default Vision;
