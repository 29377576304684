import React, { useState, useEffect, useRef } from "react";

function Hero({ image = "", children = "" }) {
  const ref = useRef();
  const img = useRef();

  const scrollListener = (e) => {
    const scrollTop = e.target.scrollingElement.scrollTop;
    const position = ref.current.offsetTop;

    img.current.style.transform = `translateY(${
      (scrollTop - position) / 2.5
    }px)`;
  };

  useEffect(() => {
    document.addEventListener("scroll", scrollListener);
    return () => document.removeEventListener("scroll", scrollListener);
  }, []);

  return (
    <section className="hero" ref={ref}>
      <div className="parallax">
        <img src={image} ref={img} />
      </div>

      <div className="desc">{children}</div>
    </section>
  );
}

export default Hero;
