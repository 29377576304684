import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

function Nav() {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  return (
    <nav>
      <div className="container">
        <Link onClick={() => setOpen(false)} to="/" className="logo">
          <img
            alt="logo"
            className="logo-full"
            src="/images/logo/logo_transparent_background.png"
          />
          <img
            alt="logo"
            className="logo-symbol"
            src="/images/logo/logo-symbol.png"
          />
        </Link>

        <div className="hamburger" onClick={() => setOpen((o) => !o)}>
          <span />
        </div>

        <div className={`menu ${open ? "open" : ""}`}>
          <Link
            onClick={() => setOpen(false)}
            to="/vision"
            className={pathname === "/vision" ? "active" : ""}
          >
            our vision
          </Link>
          <Link
            onClick={() => setOpen(false)}
            to="/values"
            className={pathname === "/values" ? "active" : ""}
          >
            our values
          </Link>
          <Link
            onClick={() => setOpen(false)}
            to="/mission"
            className={pathname === "/mission" ? "active" : ""}
          >
            mission - areas of focus
          </Link>
          <Link
            onClick={() => setOpen(false)}
            to="/what-we-do"
            className={pathname === "/what-we-do" ? "active" : ""}
          >
            what we do
          </Link>
          <Link
            onClick={() => setOpen(false)}
            to="/where-we-operate"
            className={pathname === "/where-we-operate" ? "active" : ""}
          >
            where we operate
          </Link>
          <Link
            onClick={() => setOpen(false)}
            to="/contact-us"
            className={pathname === "/contact-us" ? "active" : ""}
          >
            contact us
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
